import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { FlagTransactionDialog } from 'domains/transaction/dialogs';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { SelectionPageToast } from 'layout';
import { TransactionReviewStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  ids: string[];
  isDetailsPageOpen: boolean;
  hasExportedTransactions: boolean;
  hasMultipleAccountingTransactions: boolean;
  onUpdate: () => void;
}

const TransactionsReviewToast = ({
  ids,
  isDetailsPageOpen,
  hasExportedTransactions,
  hasMultipleAccountingTransactions,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const history = useHistory();
  const location = useLocation();
  const { detailsParams } = useIsDetailsPageOpen('/:transactionId', true);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [
    isFlagTransactionDialogOpen,
    setIsFlagTransactionDialogOpen,
  ] = useState(false);

  const closeDetailsView = () => {
    const transactionId = detailsParams?.transactionId;
    if (transactionId && ids.includes(transactionId)) {
      history.replace({
        pathname: location.pathname.replace(`/${transactionId}`, ''),
        search: location.search,
      });
    }
  };

  const approveTransactions = async () => {
    try {
      setIsLoading(true);
      await api.updateTransactionsReview({
        transactionIds: ids,
        reviewStatus: TransactionReviewStatus.approved,
      });
      if (!mounted.current) return;
      onUpdate();
      closeDetailsView();
      setIsApproveDialogOpen(false);
      setIsLoading(false);
      enqueueSnackbar(t('transactionsNeedsReviewPage.approveSuccess'));
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  if (!ids.length) return null;

  return (
    <>
      <SelectionPageToast
        width={isDetailsPageOpen ? 'sm' : 'lg'}
        isDetailsPageOpen={isDetailsPageOpen}
        onSuccess={() => setIsApproveDialogOpen(true)}
        onCancel={() => setIsFlagTransactionDialogOpen(true)}
        confirmButtonProps={{
          color: 'success',
          children: t('transactionsNeedsReviewPage.approveBtn'),
        }}
        cancelButtonProps={{
          color: 'error',
          children: t('transactionsNeedsReviewPage.flagBtn'),
        }}
      >
        {t('transactionsNeedsReviewPage.selectedCount', {
          count: ids.length,
        })}
      </SelectionPageToast>

      <ConfirmDialog
        title={t('transactionsNeedsReviewPage.approveConfirm.title', {
          count: ids.length,
        })}
        description={
          <Trans
            i18nKey="transactionsNeedsReviewPage.approveConfirm.description"
            components={{ br: <br /> }}
            count={ids.length}
          />
        }
        open={isApproveDialogOpen}
        onClose={() => setIsApproveDialogOpen(false)}
        onSuccess={() => approveTransactions()}
        loading={isLoading}
      />

      <FlagTransactionDialog
        open={isFlagTransactionDialogOpen}
        isTransactionExported={hasExportedTransactions}
        transactionIds={ids}
        hasMultipleAccountingTransactions={hasMultipleAccountingTransactions}
        onClose={() => setIsFlagTransactionDialogOpen(false)}
        onSuccess={() => {
          onUpdate();
          closeDetailsView();
          setIsFlagTransactionDialogOpen(false);
        }}
      />
    </>
  );
};

export default TransactionsReviewToast;

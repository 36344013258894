import { TFunction } from 'i18next';
import moment from 'moment';
import {
  OnboardingItemStatus,
  OnboardingSectionType,
  OnboardingTask,
  OnboardingTaskType,
} from 'services/constants';

export const getUrlFromOnboardingKeyType = (
  itemType: OnboardingSectionType | OnboardingTaskType
) => itemType.toLowerCase().split('_').join('-');

// revert type back from URL
export const getOnboardingKeyTypeFromUrl = <
  T extends OnboardingSectionType | OnboardingTaskType
>(
  itemKey: string
) => itemKey.toUpperCase().split('-').join('_') as T;

// Used as second line for tasks list in section.
export const getTaskPreselectedText = (task: OnboardingTask, t: TFunction) => {
  switch (task.type) {
    case OnboardingTaskType.organizationName:
      return task.data.tradeName.value || '';
    case OnboardingTaskType.registeredAddress: {
      // todo: logic not provided yet
      const addressArray = [];
      if (task.data.streetName.value)
        addressArray.push(
          task.data.streetName.value +
            (task.data.streetNumber.value
              ? ` ${task.data.streetNumber.value}`
              : '')
        );
      if (task.data.postalCode.value)
        addressArray.push(task.data.postalCode.value);
      return addressArray.join(', ') || '';
    }
    case OnboardingTaskType.registeredNumber:
      return task.data.registrationNumber.value || '';
    case OnboardingTaskType.foundationDate:
      return task.data.foundationDate.value
        ? moment(task.data.foundationDate.value).format('DD.MM.YYYY')
        : '';
    case OnboardingTaskType.vatId:
      return task.data.vatId.value || '';
    case OnboardingTaskType.annualRevenue:
      return task.data.annualRevenue.value && t
        ? t(`annualRevenueLabels.${task.data.annualRevenue.value}`)
        : '';
    case OnboardingTaskType.balanceSheetTotal:
      return task.data.balanceSheetTotal.value && t
        ? t(`balanceSheetTotalLabels.${task.data.balanceSheetTotal.value}`)
        : '';
    case OnboardingTaskType.staffHeadcount:
      return task.data.staffHeadcount.value && t
        ? t(`staffHeadcountLabels.${task.data.staffHeadcount.value}`)
        : '';

    default:
      return '';
  }
};

export const getTaskNavigationLinks = (
  tasks: OnboardingTask[],
  currentType?: OnboardingTaskType
) => {
  if (!currentType)
    return { prevTypeUrl: '', nextTypeUrl: '', isTaskNavigationEnabled: false };

  const types = tasks
    .filter((task) => task.status === OnboardingItemStatus.requiresAction)
    .map((task) => task.type);
  const currentPositionIndex = types.findIndex((type) => type === currentType);
  if (currentPositionIndex === -1)
    return {
      prevTypeUrl: '',
      nextTypeUrl: '',
      isTaskNavigationEnabled: false,
    };
  return {
    prevTypeUrl:
      currentPositionIndex > 0
        ? getUrlFromOnboardingKeyType(types[currentPositionIndex - 1])
        : '',
    nextTypeUrl:
      currentPositionIndex < types.length - 1
        ? getUrlFromOnboardingKeyType(types[currentPositionIndex + 1])
        : '',
    isTaskNavigationEnabled: true,
  };
};

const getParsedFirstNumber = (range: string) => {
  // parse all numbers in a string
  let numbers = range.match(/\d+/g)?.map(Number);
  if (!numbers) return 0;
  return numbers[0];
};

export const sortLabelsByAmounts = <T,>(array: { value: T; label: string }[]) =>
  array.sort(
    (a, b) => getParsedFirstNumber(a.label) - getParsedFirstNumber(b.label)
  );

import { useFormik } from 'formik';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingTaskActions } from 'domains/onboarding/components';
import { Box, DatePicker, Grid, LoaderWithOverlay } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  OnboardingTaskFoundationDate,
  OnboardingTaskType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  foundationDate: Moment | null;
}

export interface Props {
  task: OnboardingTaskFoundationDate;
  prevTypeUrl: string;
  nextTypeUrl: string;
  isTaskNavigationEnabled: boolean;
  onUpdate: (newTask: OnboardingTaskFoundationDate) => void;
}

const FoundationDateTask = ({
  task,
  prevTypeUrl,
  nextTypeUrl,
  isTaskNavigationEnabled,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const { useUpdateFoundationDate } = useTanstackQuery();
  const { mutate: orgFoundationDateMutate } = useUpdateFoundationDate({
    onSuccess: (response) => {
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: {
          organization: {
            ...organization!,
            foundationDate: response.data.foundationDate.value ?? '',
          },
        },
      });
      if (!mounted.current) return;
      onUpdate(response);
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      formik.setSubmitting(false);
    },
  });

  const {
    data: { foundationDate },
  } = task;

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      foundationDate: foundationDate.value
        ? moment(foundationDate.value)
        : null,
    },
    onSubmit: (values) => {
      orgFoundationDateMutate({
        organizationId: organization!.id,
        taskId: task.id,
        // empty string should be a case, but we rely on BE and required field
        data: {
          foundationDate: values.foundationDate
            ? moment(values.foundationDate).format('YYYY-MM-DD')
            : '',
        },
      });
    },
  });

  return (
    <>
      <PageTitle
        pt={0}
        pb={3}
        title={t(`orgOnboardingTaskTitle.${OnboardingTaskType.foundationDate}`)}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DatePicker
            label={t('orgOnboardingFoundationDateTask.foundationDateLabel')}
            disabled={formik.isSubmitting || !foundationDate.editable}
            {...formik.getFieldProps('foundationDate')}
            onChange={(newDate) =>
              formik.setFieldValue('foundationDate', newDate)
            }
            maxDate={moment().subtract(1, 'days')}
            onError={(error) => {
              if (!error) {
                formik.setFieldError('foundationDate', '');
                return;
              }
              formik.setFieldError(
                'foundationDate',
                error === 'maxDate'
                  ? t('orgOnboardingFoundationDateTask.foundationDatePastError')
                  : t(
                      'orgOnboardingFoundationDateTask.foundationDateInvalidError'
                    )
              );
            }}
            onAccept={() => formik.setFieldError('foundationDate', '')}
            slotProps={{
              field: {
                format: 'DD.MM.YYYY',
              },
              textField: {
                InputProps: { notched: false },
                size: 'medium',
                error: !!formik.errors.foundationDate,
                helperText: formik.errors.foundationDate,
              },
            }}
          />
        </Grid>
      </Grid>

      <Box mt={3}>
        <OnboardingTaskActions
          prevTypeUrl={prevTypeUrl}
          nextTypeUrl={nextTypeUrl}
          isTaskNavigationEnabled={isTaskNavigationEnabled}
          disabled={
            formik.isSubmitting ||
            (!formik.values.foundationDate && foundationDate.required) ||
            !formik.dirty ||
            !!formik.errors.foundationDate
          }
          onSuccess={formik.handleSubmit}
        />
      </Box>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default FoundationDateTask;

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { SettingsAccountingLayout } from 'domains/settings/layout';
import withPageConfig from 'hoc/withPageConfig';
import { FeatureModuleKey } from 'services/constants';
import BmdSubPage from './BmdSubPage';
import CardAccountsSubPage from './CardAccountsSubPage';
import CategoriesSubPage from './CategoriesSubPage';
import CodatSubPage from './CodatSubPage';
import CustomFieldDropdownOptionsPage from './CustomFieldDropdownOptionsPage';
import DatevSubPage from './DatevSubPage';
import GeneralSubPage from './GeneralSubPage';
import LocoiaSubPage from './LocoiaSubPage';
import ProjectsSubPage from './ProjectsSubPage';
import SuppliersSubPage from './SuppliersSubPage';
import TeamsSubPage from './TeamsSubPage';
import VatRatesSubPage from './VatRatesSubPage';

const AccountingPage = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        {/* Subpages without navigation tabs */}
        <Route
          path={`${path}/general/custom-fields-dropdown-options/:fieldId`}
          component={CustomFieldDropdownOptionsPage}
        />

        {/* All main pages/subpages */}
        <SettingsAccountingLayout>
          <Route exact path={path} component={GeneralSubPage} />
          <Route exact path={`${path}/general`} component={GeneralSubPage} />
          <Route path={`${path}/categories`} component={CategoriesSubPage} />
          <Route path={`${path}/vat-rates`} component={VatRatesSubPage} />
          <Route path={`${path}/projects`} component={ProjectsSubPage} />
          <Route path={`${path}/teams`} component={TeamsSubPage} />
          <Route path={`${path}/suppliers`} component={SuppliersSubPage} />
          <Route path={`${path}/accounts`} component={CardAccountsSubPage} />
          <Route path={`${path}/datev`} component={DatevSubPage} />
          <Route path={`${path}/bmd`} component={BmdSubPage} />
          <Route path={`${path}/locoia`} component={LocoiaSubPage} />
          <Route path={`${path}/accounting-system`} component={CodatSubPage} />
        </SettingsAccountingLayout>
      </Switch>
    </>
  );
};

export default withPageConfig(AccountingPage, {
  featureModule: FeatureModuleKey.accountingFeatures,
});

export const viewOwners = {};
export const actionOwnerTeamTags: any = {
  '/': ['Groovy'],
  '/admin/account': ['Crafty'],
  '/admin/cards': ['Fancy'],
  '/admin/cards/:cardId': ['Fancy'],
  '/admin/dashboard': ['Fancy'],
  '/admin/export': ['Crafty'],
  '/admin/export/:transactionId': ['Crafty'],
  '/admin/integrations': ['Crafty'],
  '/admin/members': ['Fancy'],
  '/admin/members/:memberId': ['Fancy'],
  '/admin/merchants': ['Mighty'],
  '/admin/merchants/:merchantId': ['Mighty'],
  '/admin/onboarding-role': ['Groovy'],
  '/admin/onboarding': ['Groovy'],
  '/admin/partner': ['Fancy'],
  '/admin/pliant-earth': ['Fancy'],
  '/admin/profile': ['Fancy'],
  '/admin/rewards': ['Fancy'],
  '/admin/rewards/card-benefits': ['Fancy'],
  '/admin/rewards/cashback': ['Fancy'],
  '/admin/rewards/partner-deals': ['Fancy'],
  '/admin/settings': ['Groovy'],
  '/admin/settings/accounting': ['Crafty'],
  '/admin/settings/accounting/categories': ['Crafty'],
  '/admin/settings/accounting/datev': ['Crafty'],
  '/admin/settings/accounting/projects': ['Crafty'],
  '/admin/settings/accounting/suppliers': ['Crafty'],
  '/admin/settings/accounting/teams': ['Crafty'],
  '/admin/settings/accounting/vat-rates': ['Crafty'],
  '/admin/settings/datev': ['Crafty'],
  '/admin/settings/organization': ['Groovy'],
  '/admin/settings/organization/bank-accounts': ['Groovy'],
  '/admin/settings/organization/receipt-management': ['Crafty'],
  '/admin/statements': ['Mighty'],
  '/admin/teams': ['Fancy'],
  '/admin/transactions': ['Mighty', 'Crafty'],
  '/admin/transactions/:transactionId': ['Mighty', 'Crafty'],
  '/bill-payments-batches': ['Mighty'],
  '/bill-payments-batches/:batchId': ['Mighty'],
  '/cashback-payment-batches': ['Mighty'],
  '/cashback-payment-batches/:batchId': ['Mighty'],
  '/compensation-batches': ['Fancy'],
  '/compensation-batches/:batchId': ['Fancy'],
  '/merchants': ['Mighty', 'Crafty'],
  '/merchants/:merchantId': ['Mighty', 'Crafty'],
  '/organizations': ['Fancy'],
  '/organizations/:orgId/account': ['Crafty'],
  '/organizations/:orgId/cards': ['Fancy'],
  '/organizations/:orgId/cards/:cardId': ['Fancy'],
  '/organizations/:orgId/dashboard': ['Fancy'],
  '/organizations/:orgId/export': ['Crafty'],
  '/organizations/:orgId/export/:transactionId': ['Crafty'],
  '/organizations/:orgId/integrations': ['Crafty'],
  '/organizations/:orgId/members': ['Fancy'],
  '/organizations/:orgId/members/:memberId': ['Fancy'],
  '/organizations/:orgId/merchants': ['Mighty', 'Crafty'],
  '/organizations/:orgId/merchants/:merchantId': ['Mighty', 'Crafty'],
  '/organizations/:orgId/onboarding': ['Groovy'],
  '/organizations/:orgId/partner': ['Crafty'],
  '/organizations/:orgId/pliant-earth': ['Fancy'],
  '/organizations/:orgId/processing-account': ['Groovy'],
  '/organizations/:orgId/rewards': ['Fancy'],
  '/organizations/:orgId/rewards/cashback': ['Fancy'],
  '/organizations/:orgId/rewards/partner-deals': ['Fancy'],
  '/organizations/:orgId/risk-and-compliance': ['Groovy'],
  '/organizations/:orgId/risk-and-compliance/bank-submission': ['Groovy'],
  '/organizations/:orgId/risk-and-compliance/compliance-check': ['Groovy'],
  '/organizations/:orgId/risk-and-compliance/risk-assessment': ['Groovy'],
  '/organizations/:orgId/settings': ['Groovy'],
  '/organizations/:orgId/settings/accounting': ['Crafty'],
  '/organizations/:orgId/settings/accounting/general': ['Crafty'],
  '/organizations/:orgId/settings/accounting/general/custom-fields-dropdown-options/:fieldId': [
    'Crafty',
  ],
  '/organizations/:orgId/settings/accounting/categories': ['Crafty'],
  '/organizations/:orgId/settings/accounting/datev': ['Crafty'],
  '/organizations/:orgId/settings/accounting/projects': ['Crafty'],
  '/organizations/:orgId/settings/accounting/suppliers': ['Crafty'],
  '/organizations/:orgId/settings/accounting/teams': ['Crafty'],
  '/organizations/:orgId/settings/accounting/vat-rates': ['Crafty'],
  '/organizations/:orgId/settings/organization': ['Groovy'],
  '/organizations/:orgId/settings/organization/bank-accounts': [
    'Groovy',
    'Mighty',
  ],
  '/organizations/:orgId/settings/organization/integrations': ['Crafty'],
  '/organizations/:orgId/settings/organization/receipt-management': ['Crafty'],
  '/organizations/:orgId/settlements': ['Mighty'],
  '/organizations/:orgId/settlements/:settlementId': ['Mighty'],
  '/organizations/:orgId/statements': ['Mighty'],
  '/organizations/:orgId/teams': ['Fancy'],
  '/organizations/:orgId/terms': ['Fancy', 'Mighty'],
  '/organizations/:orgId/transactions': ['Mighty', 'Crafty'],
  '/organizations/:orgId/transactions/:transactionId': ['Mighty', 'Crafty'],
  '/partners': ['Crafty'],
  '/partners/candis': ['Crafty'],
  '/partners/circula': ['Crafty'],
  '/partners/mobilexpense': ['Crafty'],
  '/payout-batches': ['Mighty'],
  '/payout-batches/:batchId': ['Mighty'],
  '/profile': ['Fancy'],
  '/program-settlements': ['Mighty'],
  '/program-settlements/:settlementId': ['Mighty'],
  '/rewards': ['Fancy'],
  '/rewards/card-benefits': ['Fancy'],
  '/rewards/partner-deals': ['Fancy'],
  '/settlement-account': ['Mighty'],
  '/teams': ['Fancy'],
  '/teams/:teamId': ['Fancy'],
  '/teams/:teamId/cards': ['Fancy'],
  '/teams/:teamId/cards/:cardId': ['Fancy'],
  '/teams/:teamId/members': ['Fancy'],
  '/teams/:teamId/members/:memberId': ['Fancy'],
  '/transactions': ['Mighty', 'Crafty'],
  '/transactions/:transactionId': ['Mighty', 'Crafty'],
  '/users': ['Fancy'],
  '/users/:orgId': ['Fancy'],
  '/users/:orgId/:memberId': ['Fancy'],
  '/wallet': ['Fancy'],
};

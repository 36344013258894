import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  OnboardingSection,
  OnboardingSections,
  OnboardingSectionType,
  OnboardingTaskAnnualRevenue,
  OnboardingTaskBalanceSheetTotal,
  OnboardingTaskFoundationDate,
  OnboardingTaskOrgName,
  OnboardingTaskRegAddress,
  OnboardingTaskRegNumber,
  OnboardingTasks,
  OnboardingTaskStaffHeadcount,
  OnboardingTaskVatId,
  UpdateOrgAnnualRevenuePayload,
  UpdateOrgBalanceSheetTotalPayload,
  UpdateOrgFoundationDatePayload,
  UpdateOrgNamePayload,
  UpdateOrgRegAddressPayload,
  UpdateOrgRegNumberPayload,
  UpdateOrgStaffHeadcountPayload,
  UpdateOrgVatIdPayload,
} from 'services/constants';
import { onboardingKeys } from './queryKeys';
import useRequest from './useRequest';

export const useOnboardingQuery = (request: ReturnType<typeof useRequest>) => {
  const useGetOnboardingSections = (
    organizationId: string,
    queryOptions?: UseQueryOptions<OnboardingSections>
  ) =>
    useQuery<OnboardingSections>({
      queryKey: onboardingKeys.sections(organizationId),
      queryFn: () =>
        request<OnboardingSections>(`/onboarding/${organizationId}/sections`),
      staleTime: 1000 * 60 * 5,
      ...queryOptions,
    });

  const useGetOnboardingTasks = (
    organizationId: string,
    sectionType: OnboardingSectionType,
    queryOptions?: UseQueryOptions<OnboardingTasks>
  ) =>
    useQuery<OnboardingTasks>({
      queryKey: onboardingKeys.tasks(organizationId, sectionType),
      queryFn: () =>
        request<OnboardingTasks>(
          `/onboarding/${organizationId}/sections/tasks`,
          { params: { sectionType } }
        ),
      staleTime: 1000 * 60 * 5,
      ...queryOptions,
    });

  const useUpdateOrgName = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskOrgName,
      unknown,
      UpdateOrgNamePayload
    >
  ) =>
    useMutation<OnboardingTaskOrgName, unknown, UpdateOrgNamePayload>({
      mutationFn: ({
        organizationId,
        taskId,
        tradeName,
      }: UpdateOrgNamePayload) =>
        request<OnboardingTaskOrgName>(
          `/onboarding/${organizationId}/tasks/organization-name/${taskId}`,
          {
            method: 'put',
            data: { tradeName },
          }
        ),
      ...mutationOptions,
    });

  const useUpdateRegisteredAddress = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskRegAddress,
      unknown,
      UpdateOrgRegAddressPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/registered-address/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateRegisteredNumber = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskRegNumber,
      unknown,
      UpdateOrgRegNumberPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/registered-number/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateFoundationDate = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskFoundationDate,
      unknown,
      UpdateOrgFoundationDatePayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/foundation-date/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateVatId = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskVatId,
      unknown,
      UpdateOrgVatIdPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(`/onboarding/${organizationId}/tasks/vat-id/${taskId}`, {
          method: 'put',
          data,
        }),
      ...mutationOptions,
    });

  const useUpdateAnnualRevenue = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskAnnualRevenue,
      unknown,
      UpdateOrgAnnualRevenuePayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/annual-revenue/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateBalanceSheetTotal = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskBalanceSheetTotal,
      unknown,
      UpdateOrgBalanceSheetTotalPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/balance-sheet-total/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useUpdateStaffHeadcount = (
    mutationOptions?: UseMutationOptions<
      OnboardingTaskStaffHeadcount,
      unknown,
      UpdateOrgStaffHeadcountPayload
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, taskId, data }) =>
        request(
          `/onboarding/${organizationId}/tasks/staff-headcount/${taskId}`,
          {
            method: 'put',
            data,
          }
        ),
      ...mutationOptions,
    });

  const useSubmitOnboardingSection = (
    mutationOptions?: UseMutationOptions<
      OnboardingSection,
      unknown,
      { organizationId: string; type: OnboardingSectionType }
    >
  ) =>
    useMutation({
      mutationFn: ({ organizationId, type }) =>
        request(`/onboarding/${organizationId}/sections/submit`, {
          method: 'post',
          data: { type },
        }),
      ...mutationOptions,
    });

  return {
    useGetOnboardingSections,
    useGetOnboardingTasks,
    useUpdateOrgName,
    useUpdateRegisteredAddress,
    useUpdateRegisteredNumber,
    useUpdateFoundationDate,
    useUpdateVatId,
    useUpdateAnnualRevenue,
    useUpdateBalanceSheetTotal,
    useUpdateStaffHeadcount,
    useSubmitOnboardingSection,
  };
};

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingTaskActions } from 'domains/onboarding/components';
import {
  Box,
  FormControlLabel,
  LoaderWithOverlay,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  OnboardingItemStatus,
  OnboardingTaskOrgName,
  OnboardingTaskType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  useTradeName: boolean;
  tradeName: string;
}

export interface Props {
  task: OnboardingTaskOrgName;
  prevTypeUrl: string;
  nextTypeUrl: string;
  isTaskNavigationEnabled: boolean;
  onUpdate: (newTask: OnboardingTaskOrgName) => void;
}

const TradeNameTask = ({
  task,
  prevTypeUrl,
  nextTypeUrl,
  isTaskNavigationEnabled,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const {
    data: { legalName, tradeName },
  } = task;
  const { useUpdateOrgName } = useTanstackQuery();
  const { mutate: orgNameMutate } = useUpdateOrgName({
    onSuccess: (response) => {
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: {
          organization: {
            ...organization!,
            tradeName: response.data.tradeName.value || '',
          },
        },
      });
      if (!mounted.current) return;
      onUpdate(response);
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      formik.setSubmitting(false);
    },
  });

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      useTradeName: tradeName.required || !!tradeName.value,
      tradeName: tradeName.value || '',
    },
    validate: (values) => {
      const errors: { [k in keyof FormValues]?: string } = {};
      if (values.useTradeName && values.tradeName.trim().length > 20) {
        errors.tradeName = t('common.errors.maxLength', { maxLength: 20 });
      }
      return errors;
    },
    onSubmit: (values) => {
      const tradeName = values.useTradeName ? values.tradeName.trim() : '';
      orgNameMutate({
        organizationId: organization!.id,
        taskId: task.id,
        tradeName,
      });
    },
  });

  return (
    <>
      <PageTitle
        pt={0}
        title={t(
          `orgOnboardingTaskTitle.${OnboardingTaskType.organizationName}`
        )}
      />
      <Typography variant="body2" mb={5}>
        {tradeName.required
          ? t('orgOnboardingTradeNameTask.descriptionRequired')
          : t('orgOnboardingTradeNameTask.description')}
      </Typography>

      {!tradeName.required && (
        <RadioGroup
          value={formik.values.useTradeName}
          onChange={(_, value) =>
            formik.setFieldValue('useTradeName', value === 'true')
          }
        >
          <FormControlLabel
            disabled={formik.isSubmitting || !tradeName.editable}
            value={false}
            control={<Radio />}
            label={
              <>
                {t('orgOnboardingTradeNameTask.doNotUseTradeNameLabel')}
                <Typography variant="body2" color="textSecondary">
                  {legalName.value}
                </Typography>
              </>
            }
          />
          <FormControlLabel
            disabled={formik.isSubmitting || !tradeName.editable}
            value={true}
            control={<Radio />}
            label={t('orgOnboardingTradeNameTask.useTradeNameLabel')}
          />
        </RadioGroup>
      )}

      {formik.values.useTradeName && (
        <TextField
          disabled={formik.isSubmitting || !tradeName.editable}
          label={t('orgOnboardingTradeNameTask.tradeNameLabel')}
          {...formik.getFieldProps('tradeName')}
          error={!!formik.errors.tradeName}
          helperText={formik.errors.tradeName}
        />
      )}

      <Box mt={4}>
        <OnboardingTaskActions
          prevTypeUrl={prevTypeUrl}
          nextTypeUrl={nextTypeUrl}
          isTaskNavigationEnabled={isTaskNavigationEnabled}
          disabled={
            !tradeName.editable ||
            formik.isSubmitting ||
            (formik.values.useTradeName && !formik.values.tradeName.trim()) ||
            !!(task.status === OnboardingItemStatus.completed && !formik.dirty)
          }
          onSuccess={formik.handleSubmit}
        />
      </Box>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default TradeNameTask;
